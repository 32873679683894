<template>
  <div class="accountset enterpriselist">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=9 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">专业职称认证<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb">
                  <div class="btnaddcompany">
                    <el-button type="primary" @click="toaddauthentication"><i
                        class="el-icon-circle-plus-outline el-icon--left"></i>新增资格认证</el-button>
                  </div>
                  <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="已认证" name="first">
                      <div v-for="item in professioncertlistdata" v-bind:key="item.id" class="property-box mb-20">
                        <div class="assets-box">
                          <!-- <div class="assets-box-l">
                          
                          </div> -->
                          <div class="assets-box-img">
                            <img src="../../assets/img/pass.png">
                          </div>
                          <div class="assets-box-t js-show-asset-vip-lelvel-bg">
                              <!-- {{item.certType}} -->
                              <p class="creditcode">
                              <span> 职称类别:{{item.certType}}</span>
                            </p>
                            <p class="title">{{item.name}}</p>
                            <p class="creditcode">
                              <span> 证书编号:{{item.registerNO}}</span>
                            </p>
                            <p class=""><span>认证时间: {{item.authDate}}</span></p>
                            <p class=""><span>签发日期: {{item.issueDate}}</span></p>
                          </div>
                        </div>
                        <div class="assets-sort">
                          <div class="lt">
                          </div>
                        </div>
                      </div>
                      <template v-if="professioncertlistdata.length==0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无认证信息</span>
                        </div>
                      </template>
                    </el-tab-pane>
                    <el-tab-pane label="待审核" name="second">
                      <div v-for="item in professioncertlistdata" v-bind:key="item.id" class="property-box mb-20">
                        <div class="assets-box">
                          <!-- <div class="assets-box-l">
                            {{item.certType}}
                          </div> -->
                          <div class="assets-box-img">
                            <img src="../../assets/img/examine.png">
                          </div>
                          <div class="assets-box-t js-show-asset-vip-lelvel-bg">
                               <p class="creditcode">
                              <span> 职称类别:{{item.certType}}</span>
                            </p>
                            <p class="title">{{item.name}}</p>
                            <p class="creditcode">
                              <span> 证书编号:{{item.registerNO}}</span>
                            </p>
                            <p class=""><span>认证时间: {{item.authDate}}</span></p>
                            <!-- <p class=""><span>签发日期: {{item.issueDate}}</span></p> -->
                          </div>
                        </div>
                        <div class="assets-sort">
                          <div class="lt">
                          </div>
                        </div>
                      </div>
                      <template v-if="professioncertlistdata.length==0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无认证信息</span>
                        </div>
                      </template>
                    </el-tab-pane>
                    <el-tab-pane label="未通过" name="third">
                      <div v-for="item in professioncertlistdata" v-bind:key="item.id" class="property-box mb-20">
                        <div class="assets-box">
                          <!-- <div class="assets-box-l">
                            {{item.certType}}
                          </div> -->
                          <div class="assets-box-img">
                            <img src="../../assets/img/notpass.png">
                          </div>
                          <div class="assets-box-t js-show-asset-vip-lelvel-bg">
                            <p class="creditcode">
                              <span> 职称类别:{{item.certType}}</span>
                            </p>
                            <p class="title">{{item.name}}</p>
                            <p class="creditcode">
                              <span> 证书编号:{{item.registerNO}}</span>
                            </p>
                            <p class=""><span>认证时间: {{item.authDate}}</span></p>
                            <p class=""><span>签发日期: {{item.issueDate}}</span></p>
                          </div>
                        </div>
                        <div class="assets-sort">
                          <div class="lt">
                          </div>
                        </div>
                      </div>
                      <template v-if="professioncertlistdata.length==0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无认证信息</span>
                        </div>
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import { professioncertlist } from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      activeName: 'first',
      professioncertlistdata: [],
      verifystatus: 1,
    };
  },
  methods: {
    toaddauthentication() {
      this.$router.push('/user/authentication');
    },
    toaddcompanycert(companyid) {
      this.$router.push('/user/companycertlist?companyid=' + companyid);
    },
    handleClick(tab, event) {
      var that = this;
      if (tab.name == 'first') {
        that.verifystatus = 1;
      } else if (tab.name == 'second') {
        that.verifystatus = 0;
      } else {
        that.verifystatus = -1;
      }
      that.onprofessioncertlist();
    },
    onprofessioncertlist() {
      var that = this;
      var data = { verifyStatus: that.verifystatus };
      professioncertlist(data).then((res) => {
        if (res.data.code === 1) {
          that.professioncertlistdata = res.data.result;
        }
      });
    },
  },
  created() {
    this.onprofessioncertlist();
  },
  mounted() {},
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset .content-rb .btnaddcompany {
  position: absolute;
  right: 36px;
  z-index: 100;
}
.accountset .content-rb .btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
.accountset .content-rb .btnaddcert button {
  margin: 10px 0;
  padding: 6px 6px;
}
.accountset .content-rb .property-box {
  margin-bottom: 20px;
  background-image: linear-gradient(196deg, #72b2ff, #0074ff);
  border-radius: 4px;
}

.accountset .content-rb .assets-box-t {
  color: #fff;
  padding: 15px 0 20px 40px;
  border-radius: 4px;
  width: 500px;
  float: left;
}
.accountset .content-rb .assets-box {
  position: relative;
}
.accountset .content-rb .assets-box .assets-box-img {
  position: absolute;
  right: -2px;
  top: -18px;
}
.accountset .content-rb .assets-box-t .title {
  font-size: 20px;
  color: #fff;
  border: none;
}

.accountset .content-rb .assets-box-t p span {
  line-height: 25px;
  font-size: 13px;
  font-weight: 100;
}

.accountset .assets-box .assets-box-l {
  float: left;
  margin-top: 30px;
  margin-left: 10px;

  width: 58px;
  height: 58px;
  background-color: #66b1ff;
  border-radius: 6px;
  padding: 9px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 400;
  font-stretch: normal;
  line-height: 21px;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
}
.accountset .assets-box .assets-box-l img {
  border-radius: 50%;
}
.assets-sort {
  background: #fff;
  clear: both;
}
.assets-sort .lt {
  padding: 0 20px;
  border: 1px solid #ebeef5;
  border-top: none;
}
.assets-sort .lt .el-collapse {
  border: none;
}
.assets-sort .lt >>> .el-collapse-item__header {
  font-size: 16px;
}
.assets-sort .lt >>> .el-collapse-item:last-child .el-collapse-item__header {
  border-bottom: none;
}

.assets-sort p span {
  white-space: nowrap;
  line-height: 26px;
  font-size: 14px;
}
</style>
